import { AxiosResponse } from "axios"
import HttpService from "./http.service"
import config from "../config"

export interface AgenciesResponse {
  data: any
}

class AgencyAPI extends HttpService {
  fetchAgencyList = (): Promise<AxiosResponse<AgenciesResponse>> =>
    this.get("agencies/list")
}

export default new AgencyAPI({
  API_URL: config.API_ROOT,
})
